import Customer, { CustomerUpdateDTO } from "@/models/customer.model";
import axios from "axios";
import { CustomerDetailsResponse } from "@/api/api.dto";
import PaymentMethod from "@/models/payment-method.model";
import Address, { AddressesUpdateDTO, AddressUpdateDTO } from "@/models/address.model";

class CustomerService {
  private readonly baseUrl = "/api/vets/v1/customer/";

  public async getCustomers(query: string, statusFilter: string, page: number): Promise<any> {
    const customersResponse = await axios.get(
      `${this.baseUrl}?search=${query}&sub_status=${statusFilter}&page=${page}`
    );
    return {
      customers: customersResponse.data.resp.map((customer: any) => new Customer({}, customer)),
      pagination: customersResponse.data.pagination
    };
  }

  public getCustomer(customerId: number): Promise<Customer> {
    return axios.get(this.baseUrl + customerId).then(response => {
      return new Customer({}, response.data.resp);
    });
  }

  public getCustomerContactDetails(customerId: number): Promise<CustomerDetailsResponse> {
    return axios
      .get(`${this.baseUrl + customerId}/contact-details`)
      .then(response => response.data.resp);
  }

  public getCurrentPaymentDetails(customerId: number): Promise<PaymentMethod> {
    return axios
      .get(`${this.baseUrl}${customerId}/current-payment-method`)
      .then(response => new PaymentMethod({}, response.data.resp));
  }

  public activatePets(customerId: number): Promise<void> {
    return axios.put(`${this.baseUrl}${customerId}/activate-pets`);
  }

  public updateCustomerContactDetails(
    customerId: number,
    data: CustomerUpdateDTO
  ): Promise<CustomerUpdateDTO> {
    const url = `/api/vets/v1/customers/${customerId}/contact-details`;
    return axios.put(url, data).then(response => response.data.response);
  }

  public updateCustomerDeliveryAddress(
    customerId: number,
    data: AddressUpdateDTO
  ): Promise<Address> {
    const url = `/api/vets/v1/customers/${customerId}/delivery-address`;
    return axios.put(url, data).then(response => response.data.resp);
  }

  public updateCustomerAddresses(customerId: number, data: AddressesUpdateDTO): Promise<Address> {
    const url = `/api/vets/v1/customers/${customerId}/delivery-and-billing-address`;
    return axios.put(url, data).then(response => response.data.resp);
  }
}

const customerService = new CustomerService();
export default customerService;
