<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <slot :attrs="attrs" :on="on"></slot>
    </template>
    <v-card>
      <v-card-title> Are you sure you want to delete {{ name }}? </v-card-title>
      <v-card-actions style="padding: 1.5em">
        <v-btn color="normal" @click="confirm">Yes</v-btn>
        <v-btn color="normal" @click="dialog = false">No</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeletePetModal",
  props: ["id", "name"],
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    confirm() {
      this.$emit("delete", this.id);
      this.dialog = false;
    }
  }
};
</script>

<style scoped></style>
