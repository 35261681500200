export default class PaymentMethod {
  lastFourDigits: string;
  paymentMethodExists: boolean;

  constructor(init?: Partial<PaymentMethod>, rawData?: any) {
    if (init) {
      Object.assign(this, init);
    }
    if (rawData) {
      this.lastFourDigits = rawData?.last_four_digits;
      this.paymentMethodExists = rawData?.payment_method_exists;
    }
  }
}
